import React, { useState } from "react";
// import logo from "./logo.svg";
// import "./App.css";
import styled from "styled-components";
import useAxios from "axios-hooks";
import Loading from "react-loading";

const API_URL = "/api/cooking";

const MAIN_COLOR = "#CCC";
const PADDING = 20;
const GAP = 20;
const DEFAULT_SHOKUZAI = "にんじん、じゃがいも、玉ねぎと鶏肉があります。";
const DEFAULT_KIBUNN = "甘いものが食べたい";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: ${GAP}px;
  padding: ${PADDING}px;
  width: 100%;
`;
const Title = styled.h1``;
const Description = styled.p`
  font-size: 20px;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
`;
const Button = styled.button<{ loading?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 ${PADDING}px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background-color: ${MAIN_COLOR};
  ${(p) =>
    p.loading &&
    `
    background-color: #999;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;
const Input = styled.input<{ padding?: string }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: none;

  padding: ${(p) => (p?.padding ? p.padding : `0 ${PADDING}px`)};
  box-sizing: border-box;
  border: none;
`;
const Label = styled.label<{
  label: string;
  height?: "auto" | number;
}>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  ${(p) =>
    typeof p?.height === "number"
      ? `height: ${p.height}px;`
      : p?.height === "auto"
      ? "height:auto;"
      : "height: 50px;"}
  width: 100%;
  border: 1px solid ${MAIN_COLOR};
  border-radius: 5px;
  &:before {
    content: "${(props) => props.label}";
    display: block;
    position: absolute;
    top: 0;
    left: ${PADDING / 2}px;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    padding: 0 ${PADDING / 2}px;
  }
`;

function App() {
  const [shokuzai, setShokuzai] = useState<string>(DEFAULT_SHOKUZAI);
  const [kibunn, setKibunn] = useState<string>(DEFAULT_KIBUNN);
  const [description, setDescription] = useState<string>("");

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: API_URL,
      method: "post",
    },
    { manual: true }
  );
  const handleSubmit = () => {
    execute({
      data: {
        other: description,
        shokuzai,
        kibunn,
      },
    });
  };

  return (
    <Container>
      <Container>
        <Title>今日の献立AI β版</Title>
        <Description>
          {"料理を考えてくれるAIです。\n※結果は保証できません"}
        </Description>
      </Container>
      <Container>
        <Label label="食材">
          <Input
            type="text"
            placeholder="例) にんじん、じゃがいも、玉ねぎと鶏肉があります。"
            value={shokuzai}
            onChange={(e) => setShokuzai(e.target?.value || "")}
          />
        </Label>
        <Label label="気分">
          <Input
            type="text"
            placeholder="例) 甘いものが食べたい。/ たくさん食べたい"
            value={kibunn}
            onChange={(e) => setKibunn(e.target?.value || "")}
          />
        </Label>
        <Label label="備考" height={"auto"}>
          <Input
            as="textarea"
            placeholder="例) 五人分作ります。"
            value={description}
            padding={`${PADDING}px`}
            onChange={(e) => setDescription(e.target?.value || "")}
          />
        </Label>
        <Button onClick={handleSubmit} loading={loading}>
          料理を考える
        </Button>
      </Container>
      {error && <Container>エラーが発生しました</Container>}
      {loading && (
        <Container>料理を考えています。30秒程度かかります。</Container>
      )}
      {loading && (
        <Loading type="spin" color={MAIN_COLOR} height="100px" width="100px" />
      )}
      <Container>
        {data && data?.message && <Description>{data?.message}</Description>}
      </Container>
    </Container>
  );
}

export default App;
